import React from 'react'
import _ from 'lodash'
import { removeLastElement } from 'utils/array'
import Utils from 'utils'
import { v4 as uuidv4 } from 'uuid'
import { Table as AntTable } from 'antd'
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Legend,
  Bar as AntBar,
  ResponsiveContainer,
} from 'recharts'
import { getTickCountByInterval } from 'utils/helpers'
import { xAxisFormatter, yAxisFormatter } from '../CartesianChart'
import dimensionsConfig from '../../dimensions'
import colors from '../colors'

const MBTCasesBar = ({ resultSet, height, vizState }) => {
  const getDimensions = ({ query: { dimensions } }) => dimensions.map((dimension) => _.last(dimension.split('.')))

  const buildData = () => {
    if (!Utils.Cube.isPercentage(vizState)) return resultSet.chartPivot()

    return Utils.Cube.percentageData(resultSet.chartPivot(), vizState)
  }

  const buildYAxisProps = () => {
    if (!Utils.Cube.isPercentage(vizState)) {
      const [ticks, upperBound] = getTickCountByInterval(data, 'Cases.count', 50000)

      return {
        axisLine: false,
        tickFormatter: (v) => yAxisFormatter(v, vizState),
        tickLine: false,
        ticks,
        domain: [0, upperBound],
      }
    }

    return {
      axisLine: false,
      tickLine: true,
      unit: '%',
      ticks: [0, 25, 50, 75, 100],
    }
  }

  const tooltipFormat = (v, t) => {
    const title = t.split(',')[0]
    if (Utils.Cube.isPercentage(vizState)) return [`${Math.round(v) || 0}%`, title]

    return [Math.round(v), title]
  }

  const getOrderingForDimension = (dimension, firstDataItem) => {
    const dimensionConfig = dimensionsConfig.find((d) => d.field === dimension)

    if (dimensionConfig.ordering) return dimensionConfig.ordering
    if (!firstDataItem) return []

    const schema = Utils.Cube.getSchema(vizState)

    const items = _.reduce(firstDataItem, (res, value, key) => {
      if (!key.includes(schema)) return res
      return [...res, { key: key.split(',')[0], value }]
    }, [])
    return _.sortBy(items, (item) => -parseInt(item.value, 10)).map((item) => item.key)
  }

  const getSeriesNames = (firstDataItem) => {
    const dimensions = getDimensions(vizState)
    if (dimensions.length > 1) {
      const seriesNames = resultSet.seriesNames()
      const dimensionConfig = dimensions.map((dim, index) => {
        const config = dimensionsConfig.find((d) => d.field === dim)
        if (config.ordering) return config
        const yValues = seriesNames.map((ser) => ser.yValues[index]).sort()
        return { ...config, ordering: _.uniq(yValues) }
      })

      const { length } = dimensions
      // eslint-disable-next-line arrow-body-style
      return _.sortBy(resultSet.seriesNames(), (series) => dimensionConfig.reduce((weight, dimension, index) => {
        return weight + (_.indexOf(dimension.ordering, series.yValues[index]) + 1) * 100 ** (length - index - 1)
      }, 0))
    }

    if (dimensions.length === 0) {
      return resultSet.seriesNames()
    }

    const seriesNames = _.keyBy(resultSet.seriesNames(), (series) => removeLastElement(series.yValues))
    return getOrderingForDimension(dimensions[0], firstDataItem).map((order) => seriesNames[order])
      .filter((order) => order)
  }
  const data = buildData()

  const formatLegend = (value) => removeLastElement(value.split(',')).join(',') || 'Cases'

  if (vizState.chartType === 'mbtCasesTable') {
    return <Table data={data} vizState={vizState} />
  }

  return (
    <ResponsiveContainer height={height} key={vizState.dataFormat} width="100%">
      <BarChart data={data} margin={{ left: -10 }}>
        <XAxis axisLine={false} dataKey="x" minTickGap={20} tickFormatter={(v) => xAxisFormatter(v, vizState)} />
        <YAxis {...buildYAxisProps()} />
        <CartesianGrid vertical={false} />
        {getSeriesNames(data[0]).map((series, i) => (
          <AntBar
            dataKey={series.key}
            fill={colors[i % (colors.length - 1)]}
            key={series.key}
            name={series.title}
            stackId="a"
          />
        ))}
        <Legend formatter={formatLegend} />
        <Tooltip
          formatter={tooltipFormat}
          labelFormatter={(v) => xAxisFormatter(v, vizState, 'full')}
          wrapperStyle={{ zIndex: 2 }}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

const Table = ({ data, vizState }) => {
  const schema = Utils.Cube.getSchema(vizState)
  const dimensions = Utils.Cube.getDimensions(vizState)

  const getColumns = () => {
    const columns = dimensions.map((dim) => ({
      key: dim,
      dataIndex: dim,
      title: _.upperFirst(_.last(dim.split('.'))),
    }))
    return [{
      dataIndex: 'date', key: 'date', title: 'Date',
    }, ...columns, {
      dataIndex: 'count', key: 'count', title: 'Count',
    }]
  }

  const sort = (a, b, { key }) => {
    const dimension = _.last(key.split('.'))
    const config = dimensionsConfig.find((d) => dimension === d.field)
    if (config && config.ordering) {
      return _.indexOf(config.ordering, a[key]) - _.indexOf(config.ordering, b[key])
    }
    if (dimension === 'count') return a[key] - b[key]

    return a[key].localeCompare(b[key])
  }

  const alteredData = _.reduce(data, (result, item) => {
    const arr = _.reduce(item, (res, value, key) => {
      if (!key.includes(schema)) return res
      const keys = key.split(',')
      const row = dimensions.reduce((res, dim, i) => ({ ...res, [dim]: keys[i] }), {})
      return [...res, {
        ...row, date: item.category.split('T')[0], count: value, key: uuidv4(),
      }]
    }, [])
    return [...result, ...arr]
  }, [])

  Utils.Report.setData(vizState.id, alteredData, getColumns())

  return (
    <AntTable
      columns={getColumns().map((c) => ({ ...c, sorter: (a, b) => sort(a, b, c) }))}
      dataSource={alteredData}
      pagination={false}
      style={{ height: '300px', overflowY: 'auto' }}
    />
  )
}

export default MBTCasesBar
