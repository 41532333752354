export const getTickCountByInterval = (data, valueKey, interval, maxValue) => {
  if (!maxValue) {
    maxValue = data.length ? Math.max(...data.map((d) => d[valueKey])) : 0
  }

  interval = interval > 10000 && maxValue < 10000 ? 10000 : interval

  const upperBound = maxValue === 0 ? interval : Math.ceil(maxValue / interval) * interval
  const ticks = []
  for (let i = 0; i <= upperBound; i += interval) {
    ticks.push(i)
  }
  return [ticks, upperBound]
}
