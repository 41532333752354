import React from 'react'
import _ from 'lodash'
import {
  LineChart, Line, ResponsiveContainer, YAxis, XAxis, CartesianGrid, Legend, Tooltip,
} from 'recharts'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { Table as AntTable } from 'antd'
import Utils from 'utils'
import colors from '../colors'
import { xAxisFormatter, yAxisFormatter } from '../CartesianChart'

const labelOverrides = {
  'Cases Max Lend Amount Avg': 'Max Lend Avg',
  'Cases Loan Borrowing Avg': 'Borrowing Avg',
  'Cases Min Lend Amount Avg': 'Min Lend Avg',
  'Cases Without Reruns Max Lend Amount Avg': 'Max Lend Avg',
  'Cases Without Reruns Loan Borrowing Avg': 'Borrowing Avg',
  'Cases Without Reruns Min Lend Amount Avg': 'Min Lend Avg',
}

const isIndexed = ({ dataFormat }) => dataFormat === 'index'

const normalizeData = (resultSet, vizState) => {
  if (!isIndexed(vizState)) return resultSet.chartPivot()

  const index = resultSet.loadResponses[0].data[0]
  return resultSet.chartPivot().map((item) => ({
    ...item,
    'Cases.loanBorrowingAvg': parseFloat(item['Cases.loanBorrowingAvg']) / parseFloat(index['Cases.loanBorrowingAvg']),
    'Cases.minLendAmountAvg': parseFloat(item['Cases.minLendAmountAvg']) / parseFloat(index['Cases.loanBorrowingAvg']),
    'Cases.maxLendAmountAvg': parseFloat(item['Cases.maxLendAmountAvg']) / parseFloat(index['Cases.loanBorrowingAvg']),
  }))
}

const AffordableIndexLine = ({ resultSet, height, vizState }) => {
  const tooltipFormatter = (value, label) => {
    const formattedLabel = labelOverrides[label] || label.split(',')[0]
    if (isIndexed(vizState)) return [_.round(value, 2), formattedLabel]
    return [Math.round(value), formattedLabel]
  }

  const getTicks = () => {
    if (isIndexed(vizState)) return [0, 0.5, 1, 1.5, 2]

    return [0, 100000, 200000, 300000, 400000]
  }
  const data = normalizeData(resultSet, vizState)

  if (vizState.chartType === 'affordableIndexTable') {
    return <Table data={data} vizState={vizState} />
  }

  return (
    <ResponsiveContainer height={height} key={vizState.dataFormat} width="100%">
      <LineChart data={normalizeData(resultSet, vizState)} margin={{ left: -10 }}>
        <XAxis axisLine={false} dataKey="x" minTickGap={20} tickFormatter={(v) => xAxisFormatter(v, vizState)} />
        <YAxis
          axisLine={false}
          tickFormatter={(v) => yAxisFormatter(v, vizState)}
          tickLine={false}
          ticks={getTicks()}
        />
        <CartesianGrid vertical={false} />
        {resultSet.seriesNames().map((series, i) => (
          <Line
            dataKey={series.key}
            key={series.key}
            name={series.title}
            stroke={colors[i % (colors.length - 1)]}
          />
        ))}
        <Legend formatter={(value) => labelOverrides[value] || value.split(',')[0]} />
        <Tooltip
          formatter={tooltipFormatter}
          labelFormatter={(v) => moment(v).format('MMMM, YYYY')}
          wrapperStyle={{ zIndex: 2 }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
}

const Table = ({ data, vizState }) => {
  const schema = Utils.Cube.getSchema(vizState)

  const getColumns = () => [
    { dataIndex: 'date', key: 'date', title: 'Date' },
    { dataIndex: 'loanBorrowingAvg', key: 'loanBorrowingAvg', title: 'Loan Borrowing' },
    { dataIndex: 'maxLendAmountAvg', key: 'maxLendAmountAvg', title: 'Max Lend Amount' },
    { dataIndex: 'minLendAmountAvg', key: 'minLendAmountAvg', title: 'Min Lend Amount' },
  ]

  const sort = (a, b, { key }) => {
    if (key !== 'date') return a[key] - b[key]

    return a[key].localeCompare(b[key])
  }

  const alteredData = _.reduce(data, (result, item) => {
    const object = {
      date: item.category.split('T')[0],
      loanBorrowingAvg: _.round(item[`${schema}.loanBorrowingAvg`], 2),
      maxLendAmountAvg: _.round(item[`${schema}.maxLendAmountAvg`], 2),
      minLendAmountAvg: _.round(item[`${schema}.minLendAmountAvg`], 2),
      key: uuidv4(),
    }
    return [...result, object]
  }, [])

  Utils.Report.setData(vizState.id, alteredData, getColumns())

  return (
    <AntTable
      columns={getColumns().map((c) => ({ ...c, sorter: (a, b) => sort(a, b, c) }))}
      dataSource={alteredData}
      pagination={false}
      style={{ height: '300px', overflowY: 'auto' }}
    />
  )
}

export default AffordableIndexLine
