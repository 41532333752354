import React, { useState } from 'react'
import {
  Row,
  Col,
  Form,
  Button,
} from 'antd'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import CustomFilters from 'components/GlobalFilterComponents/CustomFilters'
import FilterByTime from 'components/GlobalFilterComponents/FilterByTime'
import GroupingByTime from 'components/GlobalFilterComponents/GroupingByTime'
import Reruns from 'components/GlobalFilterComponents/Reruns'
import { updateFilters as updateFiltersAction } from 'core/filters'
import { updateGlobalOptions } from 'core/globalOptions'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
`

const PATHS_TO_TYPE = {
  '#/': 'residential',
  '#/residential': 'residential',
  '#/btl': 'buy_to_let',
}

const GlobalFilters = () => {
  const dispatch = useDispatch()

  const type = PATHS_TO_TYPE[location.hash]
  const filtersRedux = useSelector(({ filters }) => filters[type])
  const [updatedCustomFilters, setUpdatedCustomFilters] = useState(filtersRedux)

  const filterByTimeOnStore = useSelector(({ globalOptions }) => globalOptions.filterByTime)
  const [filterByTime, setFilterByTime] = useState(filterByTimeOnStore || '')

  const groupingByTimeOnStore = useSelector(({ globalOptions }) => globalOptions.groupingByTime)
  const [groupingByTime, setGroupingByTime] = useState(groupingByTimeOnStore)

  const reRunsOnStore = useSelector(({ globalOptions }) => globalOptions.reRuns)
  const [reRuns, setReRuns] = useState(reRunsOnStore)

  const refreshFilters = () => {
    dispatch(updateGlobalOptions('filterByTime', filterByTime))
    dispatch(updateGlobalOptions('groupingByTime', groupingByTime))
    dispatch(updateGlobalOptions('reRuns', reRuns))
    dispatch(updateFiltersAction(updatedCustomFilters, type))
  }
  return (
    <Row align="top" justify="space-around" key="1" type="flex">
      <Col span={24}>
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
          <FilterByTime setFilterByTime={(value) => setFilterByTime(value)} defaultFilterByTime={filterByTime} />
          <GroupingByTime
            setGroupingByTime={(value) => setGroupingByTime(value)}
            defaultGroupingByTime={groupingByTime}
          />
          <Reruns setReRuns={(value) => setReRuns(value)} defaultReRuns={reRuns} />
          <CustomFilters
            type={PATHS_TO_TYPE[location.hash]}
            setUpdatedCustomFilters={(filters) => setUpdatedCustomFilters(filters)}
            updatedCustomFilters={updatedCustomFilters}
          />
        </Form>
      </Col>
      <Wrapper>
        <Button
          onClick={refreshFilters}
          type="primary"
        >
          Refresh
        </Button>
      </Wrapper>
    </Row>
  )
}

export default GlobalFilters
