export default () => [
  {
    id: '2',
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'mbtCasesBar', label: 'Bar' }, { value: 'mbtCasesTable', label: 'Table' }],
      },
      { name: 'measure' },
      { name: 'dimension' },
      { name: 'data_format', options: ['absolute', 'percentage'] },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: [
        ],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'residential',
            ],
          },
        ],
      },
      dataFormat: 'absolute',
      chartType: 'mbtCasesBar',
      sessionGranularity: 'month',
    },
    name: 'MBT cases - Residential',
    description: 'Overview of the composition and volume of the cases through MBT. Ability to filter by different dimensions',
    __typename: 'DashboardItem',
  },
  {
    id: '3',
    layout: {
      x: 12,
      y: 0,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'mbtCasesBar', label: 'Bar' }, { value: 'mbtCasesTable', label: 'Table' }],
      },
      { name: 'measure' },
      { name: 'dimension' },
      { name: 'data_format', options: ['absolute', 'percentage'] },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: [
        ],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      },
      dataFormat: 'absolute',
      chartType: 'mbtCasesBar',
      sessionGranularity: 'month',
    },
    name: 'MBT Cases – Buy to Let (Launched in July 2020)',
    description: 'Overview of the composition and volume of the cases through MBT. Ability to filter by different dimensions',
    __typename: 'DashboardItem',
  },
  {
    id: '4',
    layout: {
      x: 0,
      y: 9,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'affordableIndexLine', label: 'Line' }, { value: 'affordableIndexTable', label: 'Table' }],
      },
      { name: 'data_format', options: ['absolute', 'index'] },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.maxLendAmountAvg',
          'Cases.loanBorrowingAvg',
          'Cases.minLendAmountAvg',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: [],
        segments: [
          'Cases.affordable',
        ],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'residential',
            ],
          },
        ],
      },
      dataFormat: 'absolute',
      chartType: 'affordableIndexLine',
      sessionGranularity: 'month',
    },
    name: 'MBT Affordability Index - Residential',
    description: "Trend of the maximum and minimum quotes that customers are receiving from lenders over time. The data is indexed to Jan'2019 ",
    __typename: 'DashboardItem',
  },
  {
    id: '6',
    layout: {
      x: 12,
      y: 9,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'line', label: 'Line' }, { value: 'bar', label: 'Bar' }, { value: 'table', label: 'Table' },
        ],
      },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.affordablePercentage',
          'Cases.unaffordablePercentage',
          'Cases.unaffordableNoLoanPercentage',
          'Cases.unlendablePercentage',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'residential',
            ],
          },
        ],
      },
      chartType: 'bar',
      yAxis: {
        formatter: '%',
        ticks: [0, 25, 50, 75, 100],
      },
      sessionGranularity: 'month',
    },
    name: 'MBT Affordability Gap - Residential',
    description: 'Trend of the percentage of cases where no lender has met the loan amount requested by a customer',
    __typename: 'DashboardItem',
  },
  {
    id: '7',
    newFeature: true,
    layout: {
      x: 0,
      y: 13,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'line', label: 'Line' }, { value: 'bar', label: 'Bar' }, { value: 'table', label: 'Table' },
        ],
      },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.deviationAvg',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: [],
        segments: [
          'Cases.unaffordable',
          'Cases.lendableSegment',
        ],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'residential',
            ],
          },
        ],
      },
      chartType: 'bar',
      yAxis: {
        formatter: '%',
      },
      sessionGranularity: 'month',
    },
    name: 'Affordability gap to the request loan – Resi',
    description: 'Average % difference between the loan requested and the maximum loan offered',
    __typename: 'DashboardItem',
  },
  {
    id: '8',
    newFeature: true,
    layout: {
      x: 12,
      y: 13,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'affordableIndexLine', label: 'Line' }, { value: 'affordableIndexTable', label: 'Table' }],
      },

    ],
    vizState: {
      query: {
        measures: [
          'Cases.maxLendAmountAvg',
          'Cases.loanBorrowingAvg',
          'Cases.minLendAmountAvg',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: [],
        segments: [
          'Cases.affordable',
        ],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      },
      dataFormat: 'absolute',
      chartType: 'affordableIndexLine',
      sessionGranularity: 'month',
    },
    name: 'MBT Affordability Index - BTL',
    description: 'Trend of the maximum and minimum quotes that customers are receiving from lenders over time',
    __typename: 'DashboardItem',
  },
]
