export default ({ currentLenderId }) => [
  {
    id: '1001',
    layout: {
      x: 0,
      y: 0,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'line', label: 'Line' }, { value: 'bar', label: 'Bar' }, { value: 'table', label: 'Table' },
        ],
      },

    ],
    vizState: {
      query: {
        measures: [
          'CaseResults.affordablePercentage',
          'CaseResults.unaffordablePercentage',
          'CaseResults.unaffordableNoLoanPercentage',
          'CaseResults.unlendablePercentage',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        filters: [
          `CaseResults.LENDER.${currentLenderId}`,
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      },
      chartType: 'bar',
      yAxis: {
        formatter: '%',
        ticks: [0, 25, 50, 75, 100],
      },
      sessionGranularity: 'month',
    },
    name: 'Your Affordability',
    description: 'Understand the % of cases you are affordable (met the loan requested by the customer), unaffordable (did not meet the loan requested) and unlendable (do not offer a loan amount)',
    __typename: 'DashboardItem',
  },
  {
    id: '1002',
    layout: {
      x: 12,
      y: 0,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'marketAffordabilityLine', label: 'Line' },
          { value: 'marketAffordabilityBar', label: 'Bar' },
          { value: 'marketAffordabilityTable', label: 'Table' },
        ],
      },

      { name: 'lender_groups' },

    ],
    vizState: {
      titleType: 'MarketAffordability',
      query: {
        measures: [
          'CaseResults.affordablePercentage',
          'CaseResults.unaffordablePercentage',
          'CaseResults.unaffordableNoLoanPercentage',
          'CaseResults.unlendablePercentage',
        ],
        dimensions: ['CaseResults.lenderName'],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      },
      chartType: 'marketAffordabilityBar',
      lenderGroup: 'All',
      yAxis: {
        formatter: '%',
        ticks: [0, 25, 50, 75, 100],
      },
      sessionGranularity: 'month',
    },
    name: 'The Market Affordability',
    description: ' Compare yourself vs. other types of lenders on our panel',
    __typename: 'DashboardItem',
  },
  {
    id: '1003',
    layout: {
      x: 0,
      y: 9,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'topXBarChart', label: 'Bar' }, { value: 'topXTable', label: 'Table' },
        ],
      },

      {
        name: 'top_position',
        options: [
          { value: 'Top1Lenders', label: 'TOP-1' },
          { value: 'TopAmong5Lenders', label: 'Among-5' },
          { value: 'TopAmong10Lenders', label: 'Among-10' },
          { value: 'Top1LendersWithoutReruns', label: 'TOP-1 (Without Reruns)' },
          { value: 'TopAmong5LendersWithoutReruns', label: 'Among-5 (Without Reruns)' },
          { value: 'TopAmong10LendersWithoutReruns', label: 'Among-10 (Without Reruns)' },
        ],
      },

    ],
    vizState: {
      custom: 'afford_ranking',
      disabledLegend: true,
      disabledFilters: [3],
      query: [{
        measures: [
          'Top1Lenders.count',
        ],
        timeDimensions: [
          {
            dimension: 'Top1Lenders.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'Top1Lenders.lenderName',
        ],
        filters: [
          {
            dimension: 'Top1Lenders.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Top1Lenders.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }, {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }],
      chartType: 'topXBarChart',
      yAxis: {
        formatter: '%',
      },
      sessionGranularity: 'month',
    },
    name: 'Your Ranking',
    description: 'Affordable cases: View how many times you appear top of the affordability table vs. other lenders, and how many times you appear among the top 5 or top 10.',
    __typename: 'DashboardItem',
  },
  {
    id: '1006',
    newFeature: true,
    layout: {
      x: 12,
      y: 9,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'topXBarChart', label: 'Bar' }, { value: 'topXTable', label: 'Table' },
        ],
      },
      { name: 'lender_groups' },

      {
        name: 'top_position',
        options: [
          { value: 'TopAmong5Lenders', label: 'Among-5' },
          { value: 'TopAmong10Lenders', label: 'Among-10' },
          { value: 'TopAmong5LendersWithoutReruns', label: 'Among-5 (Without Reruns)' },
          { value: 'TopAmong10LendersWithoutReruns', label: 'Among-10 (Without Reruns)' },
        ],
      },

    ],
    vizState: {
      // titleType: 'TopXLenders',
      custom: 'afford_ranking',
      disabledLegend: true,
      disabledFilters: [3],
      query: [{
        measures: [
          'TopAmong5Lenders.count',
        ],
        timeDimensions: [
          {
            dimension: 'TopAmong5Lenders.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'TopAmong5Lenders.lenderName',
        ],
        filters: [
          {
            dimension: 'TopAmong5Lenders.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'TopAmong5Lenders.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }, {
        measures: [
          'Cases.count',
        ],
        timeDimensions: [
          {
            dimension: 'Cases.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [],
        filters: [
          {
            dimension: 'Cases.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'Cases.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.lenderName',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }],
      chartType: 'topXBarChart',
      yAxis: {
        formatter: '%',
      },
      lenderGroup: 'All',
      sessionGranularity: 'month',
    },
    name: 'The Market Ranking / ${topPositionLabel}',
    description: 'Affordable cases: Compare yourself vs. other types of lenders on our panel on how many times you appear among the top 5 or 10',
    __typename: 'DashboardItem',
  },
  {
    id: '1004',
    layout: {
      x: 0,
      y: 13,
      w: 12,
      h: 8,
    },
    options: [

      { name: 'dimension', type: 'single', disable_empty: true },
      {
        name: 'grouping_by_time',
        options: [
          { label: 'Year', value: 'year' },
          { label: 'Month', value: 'month' },
        ],
      },

    ],
    vizState: {

      disabledLegend: true,
      query: [{
        measures: [
          'CaseResults.averageLendersAffordable',
          'CaseResults.averageMinAffordable',
          'CaseResults.averageMaxAffordable',
          'CaseResults.averageBorrowing',
          'CaseResults.sumRankWeight',
        ],

        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'year',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.loanBorrowing',
        ],
        filters: [
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }, {
        measures: [
          'CaseResults.averageRank',
          'CaseResults.affordablePercentage',
          'CaseResults.sumRankWeight',
          'CaseResults.count',
        ],

        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'year',
          },
        ],
        order: {},
        dimensions: [
          'CaseResults.loanBorrowing',
        ],
        filters: [
          `CaseResults.LENDER.${currentLenderId}`,
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      }],
      chartType: 'benchmarkTable',
    },
    name: 'Your competitive benchmark for specific segments',
    description: 'Affordable cases: Gain more insights into your affordability results vs. the market. Ability to filter by different dimensions',
    __typename: 'DashboardItem',
  },
  {
    id: '1007',
    newFeature: true,
    layout: {
      x: 12,
      y: 13,
      w: 12,
      h: 8,
    },
    options: [
      {
        name: 'chart_type',
        options: [
          { value: 'myDeviationBar', label: 'Bar' }, { value: 'myDeviationTable', label: 'Table' },
        ],
      },

    ],
    vizState: {
      query: {
        measures: [
          'CaseResults.count',
        ],
        timeDimensions: [
          {
            dimension: 'CaseResults.updatedAt',
            granularity: 'month',
          },
        ],
        dimensions: ['CaseResults.deviationFrames'],
        segments: [
          'CaseResults.unaffordable',
          'CaseResults.lendableSegment',
        ],
        filters: [
          `CaseResults.LENDER.${currentLenderId}`,
          {
            dimension: 'CaseResults.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'CaseResults.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      },
      chartType: 'myDeviationBar',
      dataFormat: 'percentage',
      yAxis: {
        formatter: '%',
      },
      sessionGranularity: 'month',
    },
    name: 'Your deviation from the loan requested',
    description: 'Unaffordable cases: Understand how far away your affordability result was from the loan requested',
    __typename: 'DashboardItem',
  },
  {
    id: '1101',
    newFeature: true,
    layout: {
      x: 0,
      y: 17,
      w: 12,
      h: 8,
    },
    options: [

    ],
    vizState: {

      disabledLegend: true,
      query: {
        measures: [
          'ExcludedReasons.count',
        ],

        timeDimensions: [
          {
            dimension: 'ExcludedReasons.updatedAt',
          },
        ],
        order: {},
        dimensions: [
          'ExcludedReasons.name',
        ],
        filters: [
          `ExcludedReasons.LENDER.${currentLenderId}`,
          {
            dimension: 'ExcludedReasons.caseType',
            operator: 'equals',
            values: [
              'buy_to_let',
            ],
          },
          {
            dimension: 'ExcludedReasons.updatedAt',
            operator: 'afterDate',
            values: [
              '2023-01-01',
            ],
          },
        ],
      },
      chartType: 'excludedReasonsTable',
    },
    name: 'Top 10 reasons for "will not lend"',
    description: 'Will not lend cases: Key reasons for not lending to borrowers. Note, one case might have multiple reasons. Contact us if you would like the full list',
    __typename: 'DashboardItem',
  },
]
