import React, { useState } from 'react'
import _ from 'lodash'
import { Card, Button } from 'antd'
import styled from 'styled-components'
import Utils from 'utils'
import { getElixirBackendUrl } from 'utils/paths'
import CustomTitles from '../customTitles'
import EditModal from './EditModal'

const StyledCard = styled(Card)`
  box-shadow: 0px 2px 4px rgba(141, 149, 166, 0.1);
  border-radius: 4px;

  .ant-card-head {
    border: none;
    padding-bottom: 16px;
  }
  .ant-card-body {
    padding-top: 0px;
    div:first-child {
      margin-top: 5px;
      margin-bottom: 5px;
      min-height: 65px;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  & > button:last-child {
    margin-left: 10px;
  }
`

const Description = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
`

const DashboardItemDropdown = ({
  item, setModal, handleRemount, isAdmin,
}) => {
  const handleExport = () => {
    // extract to independent file
    fetch(`${getElixirBackendUrl()}/lender_portal/export`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data: Utils.Report.getData(item.id) || [] }),
    }).then((res) => res.blob())
      .then((blob) => {
        const objUrl = window.URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = objUrl
        link.download = `MBT Lender Portal ${item.name}.csv`
        link.click()

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => { window.URL.revokeObjectURL(objUrl) }, 250)
      })
  }

  return (
    <ButtonWrapper>
      {isAdmin ? (
        <Button onClick={handleRemount}>
          Refresh
        </Button>
      ) : null}
      {item.options.length ? (
        <Button onClick={() => setModal(true)}>
          Edit
        </Button>
      ) : null}
      {_.toLower(item.vizState.chartType).includes('table') ? <Button onClick={handleExport}>Export</Button> : null}
    </ButtonWrapper>
  )
}

const DashboardItem = ({
  itemId, children, data, setData, item, isAdmin,
}) => {
  const [modal, updateModal] = useState(false)
  const [refreshKey, setRefreshKey] = useState(_.uniqueId(itemId))

  const setModal = (value) => {
    updateModal(value)
  }

  const handleRemount = () => {
    setRefreshKey(_.uniqueId(itemId))
  }

  const getTitle = () => {
    if (item.vizState.titleType) {
      const Title = CustomTitles[item.vizState.titleType]
      return <Title vizState={item.vizState} name={item.name} />
    }

    return _.template(item.name)(getTemplateOptions())
  }

  const getTemplateOptions = () => {
    if (!item.vizState.query) return {}

    const schema = Utils.Cube.getSchema(item.vizState)
    const topPosition = item.options.find((opt) => opt.name === 'top_position')
    const opts = {
      topPositionLabel: '${topPositionLabel}',
    }
    if (topPosition) {
      const option = topPosition.options.find((opt) => opt.value === schema)
      if (option) {
        opts.topPositionLabel = option.label
      }
    }
    return opts
  }

  const getDescription = () => _.template(item.description)(getTemplateOptions())

  return (
    <>
      <EditModal
        data={data}
        item={item}
        itemId={itemId}
        modal={modal}
        setData={setData}
        setModal={setModal}
        title={item.name}
      />
      <StyledCard
        bordered={false}
        extra={(
          <DashboardItemDropdown
            item={item}
            itemId={itemId}
            setModal={setModal}
            handleRemount={handleRemount}
            isAdmin={isAdmin}
          />
        )}
        style={{ height: '100%', width: '100%' }}
        bodyStyle={{ overflowY: 'auto', height: 'calc(100% - 48px)' }}
        title={getTitle()}
      >
        {item.description && <Description>{getDescription()}</Description>}
        {React.cloneElement(children, { key: refreshKey })}
      </StyledCard>
    </>
  )
}

export default DashboardItem
