import React from 'react'
import _ from 'lodash'
import { Table } from 'antd'
import Utils from 'utils'
import { connect } from 'react-redux'

const DESCRIPTIONS = [
  { description: 'No of Cases Shown For (no of cases)' },
  { description: 'No Affordable For (volume number & %)' },
  { description: 'Product Shown in top 100 for (% and volume number)' },
  { description: 'Median Loan Size Affordable Case' },
  { description: 'Initial Product Rate (lowest)' },
]
const getColumns = (vizState, lenderName) => {
  const first = [
    {
      dataIndex: 'description',
      align: 'left',
      key: 'description',
      title: ' ',
    },
    {
      dataIndex: lenderName,
      align: 'center',
      key: lenderName,
      title: 'Your Lender (user)',
    }]
  const dynamic = vizState.lendersProducts.filter((c) => !_.isEmpty(c.lender)).map((competitor) => (
    {
      dataIndex: competitor.lender[1],
      align: 'center',
      key: competitor.lender[0],
      title: `Competitor Lender ${competitor.lender[1]}`,
    }))
  return [...first, ...dynamic]
}

const connector = connect(({ currentLenderId, user }) => ({ currentLenderId, user }), {})

const getPercent = (value, baseValue) => {
  if (!baseValue) return '-'
  const percent = Math.round((value * 100) / baseValue)
  return `${value} (${percent}%)`
}

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const buildDataForLender = ({
  allCases, affordableCases, avgAffordableCases, lenderName, vizState, lenderStats,
}) => {
  const total = getByLenderName(allCases, lenderName, { vizState, index: 0, key: 'count' })
  const lenderAffordable = getByLenderName(affordableCases, lenderName, { vizState, index: 0, key: 'count' })
  const lenderAvgAffordable = getByLenderName(
    avgAffordableCases, lenderName, { vizState, index: 0, key: 'averageBorrowing' },
  )
  const statsCount = sumAll(lenderStats, { vizState, index: 3, key: 'count' })
  const statsMinByInitialRate = minByAll(lenderStats, { vizState, index: 3, key: 'minByInitialRate' })
  return {
    data: [
      total,
      getPercent(lenderAffordable, total),
      getPercent(statsCount, total),
      formatter.format(lenderAvgAffordable),
      statsMinByInitialRate === undefined ? '-' : `${statsMinByInitialRate}%`,
    ],
    lenderName,
  }
}
// const getSchema = ()
const buildData = (resultSet, user, vizState, currentLenderId) => {
  const lenderName = (user.lenders.find((l) => l.id === currentLenderId) || {}).name
  const [
    { data: allCases },
    { data: affordableCases },
    { data: avgAffordableCases },
    { data: myLenderStats },
    ...lenderStats
  ] = resultSet.loadResponses
  const myLenderData = buildDataForLender({
    allCases, affordableCases, avgAffordableCases, lenderName, vizState, lenderStats: myLenderStats,
  })

  const competitorData = vizState.lendersProducts.filter(
    (c) => !_.isEmpty(c.lender),
  ).map((competitor, index) => buildDataForLender({
    allCases,
    affordableCases,
    avgAffordableCases,
    lenderName: competitor.lender[1],
    vizState,
    lenderStats: lenderStats[index].data,
  }))

  return DESCRIPTIONS.map((item, index) => {
    const result = { ...item, [myLenderData.lenderName]: myLenderData.data[index] }
    return competitorData.reduce((res, { data, lenderName }) => ({
      ...res, [lenderName]: data[index],
    }), result)
  })
}

const getSchemaByIndex = (vizState, index) => vizState.query[index].measures[0].split('.')[0]

const getByLenderName = (items, lenderName, { vizState, index, key }) => {
  const schema = getSchemaByIndex(vizState, index)
  return _.sumBy(
    items.filter(
      (item) => item[`${schema}.lenderName`] === lenderName,
    ),
    (item) => _.round(item[`${schema}.${key}`]),
  )
}

const sumAll = (items, { vizState, index, key }) => {
  const schema = getSchemaByIndex(vizState, index)
  return _.sumBy(items, (item) => _.round(item[`${schema}.${key}`])) || 0
}

const minByAll = (items, { vizState, index, key }) => {
  const schema = getSchemaByIndex(vizState, index)
  const result = _.map(items, (item) => parseFloat(item[`${schema}.${key}`], 10))
  const filteredResult = result.filter((num) => !isNaN(num))
  if (filteredResult.length === 0) return 0
  return _.round(_.min(filteredResult), 2)
}

const SourcingInsightsTable = ({
  resultSet, vizState, height, currentLenderId, user,
}) => {
  // console.log('resultSet', resultSet)
  const lenderName = (user.lenders.find((l) => l.id === currentLenderId) || {}).name
  const columns = getColumns(vizState, lenderName)

  const data = buildData(resultSet, user, vizState, currentLenderId)
  Utils.Report.setData(vizState.id, data, columns)

  const schema = Utils.Cube.getSchema(vizState)
  return (
    <>
      <div style={{ maxHeight: `${height - 4}px`, overflowY: 'auto' }}>
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          rowKey={(item) => item[`${schema}.name`]}
        />
      </div>
    </>
  )
}

export default connector(SourcingInsightsTable)
