import React from 'react'
import _ from 'lodash'
import Utils from 'utils'
import { v4 as uuidv4 } from 'uuid'
import { Table as AntTable } from 'antd'
import {
  BarChart,
  Bar,
  LineChart,
  Line,
} from 'recharts'
import CartesianChart from '../CartesianChart'
import colors from '../colors'

const MarketAffordabilityLine = ({ resultSet, height, vizState }) => {
  const getData = () => {
    const schema = Utils.Cube.getSchema(vizState)
    const affordKey = `${schema}.affordablePercentage`
    const unaffordKey = `${schema}.unaffordablePercentage`
    const unaffordNoLoanKey = `${schema}.unaffordableNoLoanPercentage`
    const unlendKey = `${schema}.unlendablePercentage`
    const data = resultSet.chartPivot()
    const selectedLenderNames = vizState.lenders.map((l) => l[1])
    const res = data.map((object) => {
      let alteredObject = _.reduce(object, (res, v, k) => {
        const lenderArr = k.split(',')
        if (lenderArr.length !== 2 || !lenderArr[1].includes(`${schema}.`)) return { ...res, [k]: v }

        const lenderName = lenderArr[0]
        const type = lenderArr[1]
        if ((!selectedLenderNames.length || selectedLenderNames.includes(lenderName))) {
          return { ...res, [type]: [...res[type], v] }
        }
        return res
      }, {
        [affordKey]: [],
        [unaffordKey]: [],
        [unaffordNoLoanKey]: [],
        [unlendKey]: [],
      })

      alteredObject = _.reduce(alteredObject[affordKey], (res, _v, i) => {
        if (!alteredObject[affordKey][i] && !alteredObject[unaffordKey][i]
          && !alteredObject[unaffordNoLoanKey][i] && !alteredObject[unlendKey][i]) {
          return res
        }
        const afford = [...res[affordKey], alteredObject[affordKey][i]]
        const unafford = [...res[unaffordKey], alteredObject[unaffordKey][i]]
        const unaffordNoLoan = [...res[unaffordNoLoanKey], alteredObject[unaffordNoLoanKey][i]]
        const unlend = [...res[unlendKey], alteredObject[unlendKey][i]]
        return {
          ...res,
          [affordKey]: afford,
          [unaffordKey]: unafford,
          [unaffordNoLoanKey]: unaffordNoLoan,
          [unlendKey]: unlend,
        }
      }, {
        ...alteredObject,
        [affordKey]: [],
        [unaffordKey]: [],
        [unaffordNoLoanKey]: [],
        [unlendKey]: [],
      })
      return {
        ...alteredObject,
        [affordKey]: _.mean(alteredObject[affordKey]),
        [unaffordKey]: _.mean(alteredObject[unaffordKey]),
        [unaffordNoLoanKey]: _.mean(alteredObject[unaffordNoLoanKey]),
        [unlendKey]: _.mean(alteredObject[unlendKey]),
      }
    })
    return res
  }

  const isBar = () => vizState.chartType === 'marketAffordabilityBar'

  if (vizState.chartType === 'marketAffordabilityTable') {
    return <Table data={getData()} vizState={vizState} />
  }

  const getProps = (i) => (isBar()
    ? { stackId: 'a', fill: colors[i % (colors.length - 1)] }
    : { stroke: colors[i % (colors.length - 1)] })

  const getSeriesNames = () => {
    const schema = Utils.Cube.getSchema(vizState)
    return [
      {
        key: `${schema}.affordablePercentage`,
        title: 'Case Results Affordable Percentage',
        yValues: [
          `${schema}.affordablePercentage`,
        ],
      },
      {
        key: `${schema}.unaffordablePercentage`,
        title: 'Case Results Unaffordable Percentage',
        yValues: [
          `${schema}.unaffordablePercentage`,
        ],
      },
      {
        key: `${schema}.unaffordableNoLoanPercentage`,
        title: 'Case Results Unaffordable No Loan Percentage',
        yValues: [
          `${schema}.unaffordableNoLoanPercentage`,
        ],
      },
      {
        key: `${schema}.unlendablePercentage`,
        title: 'Case Results Unlendable Percentage',
        yValues: [
          `${schema}.unlendablePercentage`,
        ],
      },
    ]
  }

  const InnerComponent = isBar() ? Bar : Line

  return (
    <CartesianChart
      ChartComponent={isBar() ? BarChart : LineChart}
      data={getData()}
      height={height}
      vizState={vizState}
    >
      {getSeriesNames().map((series, i) => (
        <InnerComponent
          dataKey={series.key}
          key={series.key}
          name={series.title}
          {...getProps(i)}
        />
      ))}
    </CartesianChart>
  )
}

const Table = ({ data, vizState }) => {
  const schema = Utils.Cube.getSchema(vizState)

  const getColumns = () => [
    { dataIndex: 'date', key: 'date', title: 'Date' },
    { dataIndex: 'affordablePercentage', key: 'affordablePercentage', title: 'Affordable' },
    { dataIndex: 'unaffordablePercentage', key: 'unaffordablePercentage', title: 'Unaffordable' },
    { dataIndex: 'unaffordableNoLoanPercentage', key: 'unaffordableNoLoanPercentage', title: 'Unaffordable (No Loan)' },
    { dataIndex: 'unlendablePercentage', key: 'unlendablePercentage', title: 'Your Will not lend' },
  ]

  const sort = (a, b, { key }) => {
    if (key !== 'date') return a[key] - b[key]

    return a[key].localeCompare(b[key])
  }

  const alteredData = _.reduce(data, (result, item) => {
    const object = {
      date: item.category.split('T')[0],
      affordablePercentage: _.round(item[`${schema}.affordablePercentage`], 2),
      unaffordablePercentage: _.round(item[`${schema}.unaffordablePercentage`], 2),
      unaffordableNoLoanPercentage: _.round(item[`${schema}.unaffordableNoLoanPercentage`], 2),
      unlendablePercentage: _.round(item[`${schema}.unlendablePercentage`], 2),
      key: uuidv4(),
    }
    return [...result, object]
  }, [])

  Utils.Report.setData(vizState.id, alteredData, getColumns())

  return (
    <AntTable
      columns={getColumns().map((c) => ({ ...c, sorter: (a, b) => sort(a, b, c) }))}
      dataSource={alteredData}
      pagination={false}
      style={{ height: '300px', overflowY: 'auto' }}
    />
  )
}

export default MarketAffordabilityLine
