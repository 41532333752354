import React, { useState } from 'react'
import {
  Layout, Menu, Drawer, Button,
} from 'antd'
import { connect } from 'react-redux'
import LocalStorage from 'utils/LocalStorage'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import HasAccess from 'core/HasAccess'
import { updateCurrentLenderId } from 'core/currentLenderId'
import { getMbtMainUrl } from 'utils/paths'
import GlobalFilters from './GlobalFilters'

const { SubMenu } = Menu

const StyledHeader = styled(Layout.Header)`
  && {
    padding: 0 28px;
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 75px;
    background: #f0f2f5;
  }
`

const StyledMenu = styled(Menu)`
  background: transparent;
  line-height: 41px;
  display: block;
`

const MenuItemStyled = styled(Menu.Item)`
  && {
    top: 4px;
    border-bottom: 1.5px solid transparent;

    &:hover {
      border-bottom: 1.5px solid transparent;
      & a {
        color: #166e75;
        opacity: 1;
      }
    }
  }
  &&.ant-menu-item-selected {
    &::after {
      color: white;
      border-bottom: 1.5px solid #32585a;
    }

    & a {
      opacity: 1;
    }
  }
  && a {
    color: #166e75;
    opacity: 0.9;
    font-weight: bold;
    letter-spacing: 0.01em;
  }
`

const MBTLogo = styled.div`
  float: left;
  margin-right: 40px;
`

const StyledButton = styled(Button)`
  margin-top: 20px;
  float: right;
`

const StyledSubMenu = styled(SubMenu)`
  float: right;
  padding-right: 0;
  padding-left: 8;
  border-bottom-style: none;
`

const MetaInfo = styled(Menu.Item)`
  float: right;
  color: #166e75;

  &:hover {
    cursor: default;
    color: #166e75 !important;
  }

  &&::after {
    border-bottom: 0px !important;
  }
`

const StyledHr = styled.hr`
  color: #000000;
  background-color: #000000;
  height: 0.1;
  width: 100%;
  font-size: 4px;
`

const StyledLenderInfo = styled.div`
  display: inline-block;
  margin-left: 8px;
  color: #346B6E;
  font-weight: bold;
`

const connector = connect(
  ({ user, currentLenderId }) => ({ user, currentLenderId }),
  { updateCurrentLenderId },
)

const Header = ({
  location, user, updateCurrentLenderId, currentLenderId,
}) => {
  const [drawer, setDrawer] = useState(false)
  const updateCurrentLender = (lender) => {
    // TOOD (atanych): use saga here
    LocalStorage.setIn(['current_lender_id'], lender.id)
    updateCurrentLenderId(lender.id)
  }

  const getLender = () => user.lenders.find((l) => l.id === parseInt(currentLenderId, 10)) || user.lenders[0]

  const lender = getLender()

  const getMetaInfo = () => {
    if (user.roles.find((role) => role === 'subscription_analytic')) {
      return user.subscriptions[0].name
    }

    return user.companyName
  }

  return (
    <div>
      <StyledHeader>
        <a
          href={getMbtMainUrl()}
          rel="noopener noreferrer"
          target="_blank"
        >
          <MBTLogo>
            <img alt="logo" height="40" src="/mb-logo.png" />
          </MBTLogo>
        </a>

        <StyledMenu
          mode="horizontal"
          selectedKeys={[location.pathname]}
          triggerSubMenuAction="click"
        >
          {!!user.lenders.length && HasAccess.run(user.permissions, 'lpCompetitiveResi') && (
            <MenuItemStyled key="/">
              <Link to="/">RESI</Link>
            </MenuItemStyled>
          )}
          {!!user.lenders.length && HasAccess.run(user.permissions, 'lpCompetitiveBtl') && (
            <MenuItemStyled key="/btl-common">
              <Link to="/btl">BTL</Link>
            </MenuItemStyled>
          )}
          <StyledButton
            type="primary"
            onClick={() => setDrawer(true)}
          >
            Filters
          </StyledButton>
          {lender && (
            <StyledSubMenu
              selectable
              key="Lenders Menu"
              title={(<Logo lender={lender} height={40} />)}
            >
              {user.lenders.map((l, i) => (
                <Menu.Item
                  icon={<Logo lender={l} width={40} />}
                  onClick={() => updateCurrentLender(l)}
                  key={i}
                >
                  <StyledLenderInfo>
                    {l.name}
                  </StyledLenderInfo>
                </Menu.Item>
              ))}
            </StyledSubMenu>
          )}
          <MetaInfo>
            {`${user.email} (${getMetaInfo()})`}
          </MetaInfo>
        </StyledMenu>
      </StyledHeader>
      <Drawer
        title="Filters"
        width={500}
        placement="right"
        closable={false}
        onClose={() => setDrawer(false)}
        visible={drawer}
        key="right"
      >
        {/* Remove RESI, use from path */}
        <GlobalFilters />
        {/* <Button
          onClick={() => setDrawer(false)}
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '20px',
          }}
        >
          Close
        </Button> */}
      </Drawer>
      <StyledHr />
    </div>
  )
}

const Logo = ({ lender, width, height }) => {
  const getProps = () => ({
    alt: 'logo',
    width,
    height,
    src: `${getMbtMainUrl()}/images/lenders/${lender.reference}.png`,
  })

  return (
    <img {...getProps()} />
  )
}
export default connector(Header)
